.dayChart {
  width: 24.3%;
  display: flex;
  padding: 6px;
  box-sizing: border-box;
  max-height: 33%;
}
.simpleAverageChart {
  width: 100%;
  height: 230px;
  display: flex;
  margin: 6px;
  box-sizing: border-box;
  max-height: 33%;
}
@media screen and (max-device-width: 750px) {
  /* Mobile */

  .simpleAverageChart {
    min-width: 50% !important;
  }
  .blackout {
    font-size: 1.3em !important;
    padding-left: 20% !important;
  }
}

@media only screen and (min-width: 970px) {
  .dayChart:nth-child(4n-7) {
    width: 27%;
  }
}

@media only screen and (max-width: 970px) {
  .dayChart {
    width: 50%;
    min-height: 245px;
  }
  .chartsTooltiptext {
    line-height: 1em !important;
  }
  .blackout {
    font-size: 1.3em !important;
    top: 10% !important;
  }
}
@media screen and (max-width: 1100px) {
  /*narrow desktop screen */
  .simpleAverageChart {
    flex: 1;
    min-height: 100%;
  }
}
@media screen and (min-device-width: 750px) and (max-device-width: 900px) and (orientation: portrait) {
  /* ipad portrate */
  .simpleAverageChart {
    flex: 1;
  }
}

@media only screen and (min-device-width: 750px) and (max-device-width: 900px) {
  .simpleAverageChart {
    /* height: 220px !important; */
  }
}

.chartsTooltiptext {
  visibility: hidden;
  width: 100%;
  position: absolute;
  left: 0;
  text-align: center;
  top: 0;
  display: flex;
  align-items: center;
  background-color: rgba(27, 42, 62, 0.93);
  color: white;
  font-size: 0.9em;

  letter-spacing: 1.1;
  line-height: 1.5em;
  padding: 0 5px;
  opacity: 0;
  transition: opacity 0.2s;
  z-index: 1;
}
.chartsTooltiptext > p {
  width: 100%;
}

.chartsTooltip:hover .chartsTooltiptext {
  visibility: visible;

  opacity: 1;
}

.chartsInfoIcon {
  position: absolute;
  right: 7px;
  font-size: 18px;
  color: rgb(27, 83, 149);
}

.blackout {
  position: absolute;
  color: red;
  top: 6%;
  font-size: 1.5em;
  font-weight: bold;
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
