.dataChart {
  width: 24.3%;
  display: flex;
  padding: 6px;
  box-sizing: border-box;
  max-height: 33%;

}

@media only screen and (min-width: 970px) {
  .dataChart:nth-child(4n-7) {
    width: 27%;
  }
}
.chartInnerCard {
  background: #fff;
  min-width: 0;
  min-height: 0;
  padding: 6px;
  position: relative;
  box-shadow: 0px 0px 10px #3d4849;
  width: 100%;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 970px) {
  .dataChart {
    width: 100%;
    flex: unset;
  }
}

/* @media only screen and (max-width: 970px) and (orientation: landscape){
  .dataChart {
    width: 50%;
  }
} */
